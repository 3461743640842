import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Typography, IconButton, FormGroup, FormControlLabel, Switch } from '@mui/material';
import { DateTime } from 'luxon';
import { MaterialTable, Button } from '@lexcelon/react-util';
import { setError, setSuccess } from '../../alerts';

// Icons
import { Visibility } from '@mui/icons-material/';

import { listTests, listAnimalSpecies, listTestTypes, toggleTestBillable } from '../../api';

class Tests extends Component {
  constructor(props) {
    super(props);

    this.state = {
      animalSpecies: [],
      testTypes: [],
      filterOutInternal: true,
      searchString: null,
      isLoadingToggleBillable: false
    };
  }

  componentDidMount() {
    // get select options from API
    listAnimalSpecies().then((results) => {
      this.setState({ animalSpecies: results });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve animal species.');
    });

    listTestTypes({ options: { where: { isDemo: false, isComingSoon: false } } }).then(({ results }) => {
      this.setState({ testTypes: results });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve test types.');
    });
  }

  render() {
    const locationState = {
      backTo: { pathname: { pathname: '/tests', state: { tableSearchString: this.state.searchString } }, description: 'All Tests' }
    };
  
    const COLUMNS = [
      {
        title: 'Start Time',
        remoteField: 'timestamp',
        search: 'date',
        value: rowData => rowData.getTimestamp()?.toLocaleString({ ...DateTime.DATETIME_MED, timeZoneName: 'short' }),
        customSort: direction => ['timestamp', direction === 'asc' ? 'desc' : 'asc'] // Flip the direction of sorting
      },
      {
        title: 'View',
        omitFromExport: true,
        render: (rowData) => (
          <>
            <Link to={{ pathname: `/tests/${rowData.getId()}`, state: locationState }} ><IconButton><Visibility /></IconButton></Link>
          </>
        )
      },
      {
        title: 'Test ID',
        remoteField: 'id',
        search: 'column',
        value: rowData => rowData.getId(),
      },
      {
        title: 'Practice',
        remoteField: '$practice.identifier$',
        search: 'column',
        value: rowData => rowData.getPractice().getIdentifier(),
        disableSort: true
      },
      {
        title: 'Status',
        remoteField: 'status',
        search: 'select',
        render: rowData => {
          const screamingSnakeCase = rowData.getStatus() ?? '';
          const words = screamingSnakeCase.split('_');
          const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());

          let color = 'slategrey';
          switch (screamingSnakeCase) {
            case 'COMPLETED':
              color = 'green';
              break;
            case 'FAILED':
              color = 'red';
              break;
          }
          return (
            <Typography style={{ color }}>{capitalizedWords.join(' ')}</Typography>
          );
        },
        value: rowData => {
          const screamingSnakeCase = rowData.getStatus() ?? '';
          const words = screamingSnakeCase.split('_');
          const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
          return capitalizedWords.join(' ');
        },
        selectOptions: [
          { value: 'CREATED', label: 'Created' },
          { value: 'IN_PROGRESS', label: 'In Progress' },
          { value: 'CANCELLED', label: 'Cancelled' },
          { value: 'FAILED', label: 'Failed' },
          { value: 'COMPLETED', label: 'Completed' }
        ]
      },
      {
        title: 'Pos/Neg',
        remoteField: 'posNeg',
        value: rowData => {
          if (!rowData.getTestType()?.getIsResultsType()) return '';
          else if (rowData.getStatus() !== 'COMPLETED') return 'Incomplete';

          const useQualitativeResult = rowData.getTestType()?.getType() === 'LATERAL_FLOW' || rowData.getTestType()?.getUseVideo();
          return rowData.getTestedParasites()?.find(testedParasite => {
            if (useQualitativeResult) return testedParasite.getQualitativeResult() === 'POSITIVE';
            return testedParasite.getCount() > 0;
          }) != null ? 'Positive' : 'Negative';
        },
        search: 'select',
        selectOptions: [
          { value: 'POSITIVE', label: 'Positive' },
          { value: 'NEGATIVE', label: 'Negative' }
        ],
        disableSort: true
      },
      {
        title: 'Test Type',
        remoteField: 'testTypeId',
        value: rowData => `${rowData.getTestType()?.getName()} [${rowData.getTestType()?.getIsDemo() ? 'Training' : 'Normal'}]`,
        search: 'select',
        selectOptions: (this.state.testTypes.length > 1) ? (this.state.testTypes.map(value => ({ value: value.getId(), label: `${value.getName()} [${value.getIsDemo() ? 'Training' : 'Normal'}]` }))) : ([]),
        disableSort: true,
      },
      {
        title: 'Billable',
        remoteField: 'billable',
        render: rowData => rowData.getParentTestId() == null && rowData.getJointParentTestId() == null && !rowData.getTestType()?.getIsDemo() && rowData.getStatus() === 'COMPLETED' ? (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography style={{ textAlign: 'center' }}>{rowData.getBillable() ? 'Billable' : 'Not Billable'}</Typography>
            <Button disabled={this.state.isLoadingToggleBillable} onClick={() => {
              this.setState({ isLoadingToggleBillable: true });
              toggleTestBillable(rowData.getId(), { billable: !rowData.getBillable() }).then(() => {
                setSuccess('Billable status updated.');
              }).catch(error => {
                setError(error);
              }).finally(() => this.setState({ isLoadingToggleBillable: false }));
            }} >Set {rowData.getBillable() ? 'Not Billable' : 'Billable'}</Button>
          </div>
        ) : null,
        search: 'none',
        disableSort: true
      },
      {
        title: 'Animal Species',
        remoteField: '$animal.animal_species_id$',
        value: rowData => rowData.getAnimal()?.getAnimalSpecies()?.getName(),
        search: 'select',
        selectOptions: (this.state.animalSpecies.length > 1) ? (this.state.animalSpecies.map(value => ({ value: value.getId(), label: value.getName() }))) : ([]),
        disableSort: true,
      },
      {
        title: 'Animal',
        remoteField: '$animal.name$',
        value: rowData => rowData.getAnimal()?.getName(),
        search: 'column',
        disableSort: true,
      },
      {
        title: 'Client First Name',
        remoteField: '$animal.owner_first_name$',
        value: rowData => rowData.getAnimal()?.getOwnerFirstName(),
        search: 'column',
        disableSort: true,
      },
      {
        title: 'Client Last Name',
        remoteField: '$animal.owner_last_name$',
        value: rowData => rowData.getAnimal()?.getOwnerLastName(),
        search: 'column',
        disableSort: true,
      },
      {
        title: 'Veterinarian Last Name',
        remoteField: '$veterinarian.last_name$',
        value: rowData => rowData.getVeterinarian()?.getLastName(),
        search: 'column',
        disableSort: true,
      },
      {
        title: 'Lab Tech Last Name',
        remoteField: '$labTech.last_name$',
        value: rowData => rowData.getLabTech()?.getLastName(),
        search: 'column',
        disableSort: true,
      },
      {
        title: 'Animal Age',
        value: rowData => rowData.getAnimalAge(),
        hidden: true
      },
      {
        title: 'Notes',
        value: rowData => rowData.getNotes(),
        hidden: true
      },
      {
        title: 'Comments',
        value: rowData => rowData.getComments(),
        hidden: true
      },
      {
        title: 'Recipe ID',
        value: rowData => rowData.getRecipeId(),
        hidden: true
      },
      {
        title: 'Instrument Serial Number',
        value: rowData => rowData.getInstrumentSerialNumber(),
        hidden: true
      },
      {
        title: 'User',
        value: rowData => rowData.getUser()?.getFullName(),
        hidden: true
      },
      {
        title: 'Completed At',
        value: rowData => rowData.getCompletedAt()?.toLocaleString({ ...DateTime.DATETIME_MED, timeZoneName: 'short' })(),
        hidden: true
      },
      {
        title: 'Image Detection URL',
        value: rowData => rowData.getImageDetectionUrl(),
        hidden: true
      },
      {
        title: 'Confidence Thresholds',
        value: rowData => rowData.getConfidenceThresholds(),
        hidden: true
      },
      {
        title: 'Correction Factor',
        value: rowData => rowData.getCorrectionFactor(),
        hidden: true
      },
      {
        title: 'Sample Name',
        value: rowData => rowData.getSampleName(),
        hidden: true
      },
      {
        title: 'Ancylostoma',
        value: rowData => rowData.getTestedParasites()?.find(testedParasite => testedParasite.getParasiteSpeciesId() === 1)?.getCount() ?? null,
        hidden: true
      },
      {
        title: 'Toxocara',
        value: rowData => rowData.getTestedParasites()?.find(testedParasite => testedParasite.getParasiteSpeciesId() === 2)?.getCount() ?? null,
        hidden: true
      },
      {
        title: 'Trichuris',
        value: rowData => rowData.getTestedParasites()?.find(testedParasite => testedParasite.getParasiteSpeciesId() === 3)?.getCount() ?? null,
        hidden: true
      },
      {
        title: 'Ascarid',
        value: rowData => rowData.getTestedParasites()?.find(testedParasite => testedParasite.getParasiteSpeciesId() === 6)?.getCount() ?? null,
        hidden: true
      },
      {
        title: 'Strongyle',
        value: rowData => rowData.getTestedParasites()?.find(testedParasite => testedParasite.getParasiteSpeciesId() === 7)?.getCount() ?? null,
        hidden: true
      },
      {
        title: 'Strongyloides',
        value: rowData => rowData.getTestedParasites()?.find(testedParasite => testedParasite.getParasiteSpeciesId() === 11)?.getCount() ?? null,
        hidden: true
      },
      {
        title: 'Tridontophorus',
        value: rowData => rowData.getTestedParasites()?.find(testedParasite => testedParasite.getParasiteSpeciesId() === 12)?.getCount() ?? null,
        hidden: true
      },
      {
        title: 'Trichostrongyle',
        value: rowData => rowData.getTestedParasites()?.find(testedParasite => testedParasite.getParasiteSpeciesId() === 13)?.getCount() ?? null,
        hidden: true
      },
      {
        title: 'Taenia',
        value: rowData => rowData.getTestedParasites()?.find(testedParasite => testedParasite.getParasiteSpeciesId() === 5)?.getCount() ?? null,
        hidden: true
      }
    ];

    return (
      <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>Tests</Typography>

        <FormGroup>
          <FormControlLabel control={<Switch checked={this.state.filterOutInternal} onChange={e => this.setState({ filterOutInternal: e.target.checked })} />} label='Filter out tests from internal practices' />
        </FormGroup>
        <MaterialTable
          title='Tests'
          options={{
            pageSize: 100,
            flipHorizontalScroll: true,
          }}
          data={{
            mode: 'remote',
            columns: COLUMNS,
            fetchRemoteData: ({ page, rowsPerPage, where = {}, order = [] }) =>
              new Promise((resolve, reject) => {
                let options = {
                  offset: page != null && rowsPerPage != null ? page * rowsPerPage : undefined,
                  limit: rowsPerPage,
                  where,
                  order,
                };
                listTests({ options, filterOutInternal: this.state.filterOutInternal }).then((res) => {
                  resolve(res);
                }).catch((error) => {
                  reject(error);
                });
              })
          }}
          onError={(error) => {
            setError(error);
          }}
          onSearch={search => this.setState({ searchString: JSON.stringify(search) })}
          defaultSearchString={this.props.location?.state?.tableSearchString}
        />

      </div>
    );
  }
}

Tests.propTypes = {
  location: PropTypes.object.isRequired
};

export default withRouter(Tests);

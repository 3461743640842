import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CircularProgress, Container, Typography, Divider, Select, TextField, MenuItem, Dialog, DialogActions, DialogContent, DialogTitle, InputLabel, FormControl } from '@mui/material';

// APIs
import { getPractice, suspendPractice, reactivatePractice, listPracticeInstruments, listUsers, getPracticePimsStatus, linkPracticeToPims, savePracticeWhitelabelLogo, listPimsIntegrators } from '../../../api';

// Components
import { BackToButton, Button, LineItem, MaterialTable } from '@lexcelon/react-util';
import { PracticeForm, UsersTable } from '../../../components';

import { confirm, startConfirmLoading, stopConfirmLoading, closeConfirm } from '../../../alerts/confirm';

// Icons
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { clearErrors, setError, setSuccess } from '../../../alerts';
import { DateTime } from 'luxon';

//Constants
const red = '#d32f2f';

class Practice extends Component {

  constructor(props) {
    super(props);

    this.practiceId = this.props.match?.params?.id;

    this.state = {
      isLoading: false,
      practice: null,
      /** @type {import('../../../api').PimsIntegrationStatus | null} */
      pimsIntegrationStatus: null,
      inEditMode: false,
      linkingModalOpen: false,
      linkingModalLoading: false,
      showWhitelabelEdit: false,
      file: null,
      isLoadingWhitelabel: false,
      pimsIntegrators: null,
      target: null
    };
  }

  componentDidMount() {
    this.retrievePractice();

    // Retrieve the list of PIMS integrators
    listPimsIntegrators().then(pimsIntegrators => {
      this.setState({ pimsIntegrators });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve PIMS integrators.');
    });
  }

  suspendConfirm = () => {
    confirm({
      title: `Are you sure you want to suspend the practice ${this.state.practice.name}?`,
      body: 'All users under this practice will also be suspended and unable to perform tests.',
      onConfirm: () => {
        startConfirmLoading();
        suspendPractice(this.state.practice.id).then((practice) => {
          setSuccess('Successfully suspended practice!');
          this.setState({ practice });
          stopConfirmLoading();
          closeConfirm();
        }).catch(error => {
          setError(error ?? 'Error: Unable to suspend practice.');
          stopConfirmLoading();
          closeConfirm();
        });
      },
      danger: true
    });
  }

  reactivateConfirm = () => {
    confirm({
      title: `Are you sure you want to reactivate the practice ${this.state.practice.name}?`,
      body: 'This will not reactivate the practice\'s users.',
      onConfirm: () => {
        startConfirmLoading();
        reactivatePractice(this.state.practice.id).then((practice) => {
          setSuccess('Successfully reactivated practice!');
          this.setState({ practice });
          stopConfirmLoading();
          closeConfirm();
        }).catch(error => {
          setError(error ?? 'Error: Unable to reactivate practice.');
          stopConfirmLoading();
          closeConfirm();
        });
      },
      danger: true
    });
  }

  retrievePractice = async () => {
    // Retrieve the practice id from the url
    this.setState({ isLoading: true });
    try {
      const practice = await getPractice(this.practiceId);
      const pimsStatus = await getPracticePimsStatus(this.practiceId);
      this.setState({ pimsIntegrationStatus: pimsStatus, practice });
    }
    catch (error) {
      setError(error ?? 'Error: Unable to retrieve practice info. Please try again.');
    }
    finally {
      this.setState({ isLoading: false });
    }
  }

  openLinkingModal = () => {
    this.setState({ linkingModalOpen: true });
  }

  closeLinkingModal = () => {
    this.setState({ linkingModalOpen: false });
  }

  /**
   * @param {'Bitwerx'} target
   * @param {string} remotePracticeId
   */
  linkPractice = (pimsIntegratorId, remotePracticeId) => {
    this.setState({ linkingModalLoading: true });
    linkPracticeToPims(pimsIntegratorId, this.practiceId, remotePracticeId).then(() => {
      setSuccess('Successfully linked practice to PIMS!');
      this.closeLinkingModal();
      this.retrievePractice();
    }).catch(error => {
      setError(error ?? 'Error: Unable to link practice to PIMS.');
    }).finally(() => {
      this.setState({ linkingModalLoading: false });
    });
  }

  openEditMode = () => {
    this.closeLinkingModal();
    this.setState({ inEditMode: true });
  }

  closeEditMode = () => {
    this.setState({ inEditMode: false });
  }

  onSuccess = () => {
    this.retrievePractice();
    this.closeEditMode();
  }

  handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    
    if (selectedFile) {
      const validTypes = ['image/png', 'image/jpeg'];

      if (!validTypes.includes(selectedFile.type)) {
        setError('Only PNG and JPG files are allowed.');
        this.setState({ file: null }); // Reset file if invalid type
        return;
      }
      clearErrors();
      this.setState({ file: selectedFile });
    }
  }

  saveWhitelabelLogo = (e) => {
    e?.preventDefault();

    this.setState({ isLoadingWhitelabel: true });
    savePracticeWhitelabelLogo(this.practiceId, this.state.file).then(practice => {
      setSuccess('Successfully saved whitelabel logo!');
      this.setState({ showWhitelabelEdit: false, practice, isLoadingWhitelabel: false });
    }).catch(error => {
      setError(error ?? 'Error: Unable to save whitelabel logo.');
      this.setState({ isLoadingWhitelabel: false });
    });
  }

  onChangeTarget = (e) => {
    const target = e.target.value;
    this.setState({ target });
  }

  render() {
    var isSuspended = this.state.practice?.getIsSuspended();
    const backTo = { pathname: this.props.location, description: this.state.practice?.getName() ?? 'Practice' };
    return (
      <Container style={{ paddingTop: '20px' }}>
        {this.props.location?.state?.backTo != null &&
        <BackToButton to={this.props.location.state.backTo.pathname} description={this.props.location.state.backTo.description} />}

        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em' }}>{this.state.practice?.getName()}</Typography>
        <Typography variant='h6' style={{ textAlign: 'center', marginBottom: '2em', color: isSuspended ? red : 'green' }}>{isSuspended ? 'SUSPENDED' : ('ACTIVE')}{this.state.practice?.getIsDemo() ? ' - TRAINING' : ''}</Typography>

        <Dialog
          open={this.state.linkingModalOpen}
          onClose={this.closeLinkingModal}
          PaperProps={{
            component: 'form',
            onSubmit: (event) => {
              event.preventDefault();
              const formData = new FormData(event.target);
              const remotePracticeId = formData.get('remotePracticeId');
              this.linkPractice(this.state.target?.getId(), remotePracticeId);
            }
          }}
          fullWidth>
          <DialogContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '1em' }}>
            <DialogTitle>Link Practice to PIMS</DialogTitle>
            <FormControl fullWidth>
              <InputLabel>Target</InputLabel>
              <Select id='target' name='target' required label='Target' fullWidth value={this.state.target} onChange={e => this.setState({ target: e.target.value })}>
                {this.state.pimsIntegrators?.map(integrator => (
                  <MenuItem key={integrator.getId()} value={integrator}>{integrator.getName()}</MenuItem>
                ))}
              </Select>
            </FormControl>

            {this.state.target?.getRequireRemoteId() &&
            <TextField
              required
              id='remotePracticeId'
              name='remotePracticeId'
              label='Remote Practice ID'
              variant='outlined'
              fullWidth
            />}
          </DialogContent>
          <DialogActions style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center' }}>
            <Button secondary style={{ width: '48%' }} onClick={this.closeLinkingModal}>
                Cancel
            </Button>

            {/* Submit Button */}
            <Button type='submit' style={{ width: '48%' }} isLoading={this.state.linkingModalLoading}>
                Submit
            </Button>
          </DialogActions>
        </Dialog>

        {this.state.isLoading ? (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          this.state.inEditMode ? (
            <PracticeForm
              onSuccess={this.onSuccess}
              onClose={this.closeEditMode}
              practice={this.state.practice}
            />
          ) : (
            <>
              <Typography variant='h2' style={{ marginBottom: '0.5em' }}>Practice Info</Typography>
              <>
                <LineItem
                  value={this.state.practice?.getIdentifier()}
                  description='Identifier'
                />

                <LineItem
                  value={this.state.practice?.getName()}
                  description='Name'
                />
              </>

              <Typography variant='h2' style={{ marginBottom: '0.5em', marginTop: '1em' }}>Contact Info</Typography>
              <>
                <LineItem
                  value={this.state.practice?.getContactName()}
                  description='Contact Name'
                />

                <LineItem
                  value={this.state.practice?.getContactEmail()}
                  description='Contact Email'
                />

                <LineItem
                  value={this.state.practice?.getContactPhoneNumber()}
                  description='Contact Phone Number'
                />

              </>

              <Typography variant='h2' style={{ marginBottom: '0.5em', marginTop: '1em' }}>Practice Location</Typography>
              <>
                <LineItem
                  value={this.state.practice?.getAddress()?.getFullAddress()}
                  style={{ marginTop: '0' }}
                  description='Address'
                />

                <LineItem
                  value={this.state.practice?.getAddress()?.getTimeZone()}
                  description='Timezone'
                />

              </>

              {this.state.pimsIntegrationStatus && (
                <>
                  <Typography variant='h2' style={{ marginBottom: '0.5em', marginTop: '1em' }}>PIMS Integration</Typography>

                  <LineItem
                    value={
                      this.state.pimsIntegrationStatus.target
                      + (this.state.pimsIntegrationStatus.subTarget ? ` (${this.state.pimsIntegrationStatus.subTarget})` : '')
                      + ` - ${this.state.pimsIntegrationStatus.remotePracticeId}`
                    }
                    description='Target'
                    style={{ marginTop: '0' }}
                  />

                  {this.state.pimsIntegrationStatus.linkingStatus !== 'Done' && (
                    <LineItem
                      value={this.state.pimsIntegrationStatus.linkingStatus}
                      description='Linking Status'
                    />
                  )}

                  <LineItem
                    value={this.state.pimsIntegrationStatus.lastSyncedFromTarget
                      ? DateTime.fromISO(this.state.pimsIntegrationStatus.lastSyncedFromTarget).toLocaleString(DateTime.DATETIME_SHORT)
                      : 'Never'
                    }
                    description='Target Last Synced'
                  />

                  {this.state.pimsIntegrationStatus.subTarget && (
                    <LineItem
                      value={this.state.pimsIntegrationStatus.lastSyncedFromSubTarget
                        ? DateTime.fromISO(this.state.pimsIntegrationStatus.lastSyncedFromSubTarget).toLocaleString(DateTime.DATETIME_SHORT)
                        : 'Never'
                      }
                      description='Target Data Last Updated'
                    />
                  )}

                  {this.state.pimsIntegrationStatus.failedSyncs > 0 && (
                    <div style={{ color: 'red', backgroundColor: 'rgba(255, 0, 0, 0.1)',padding: '5px',borderRadius: '5px' }}>
                      <LineItem
                        value={this.state.pimsIntegrationStatus.failedSyncs}
                        description='Failed Syncs'
                        style={{ margin: '0' }}
                      />
                      <p style={{ margin: '5px 0', fontWeight: 'lighter' }}>Resets after a successful sync</p>
                    </div>
                  )}
                </>
              )}

              <div style={{ marginTop: '50px' }}>
                <Button secondary onClick={this.openEditMode}>Edit Practice</Button>
              </div>

              {!this.state.pimsIntegrationStatus && (
                <div style={{ marginTop: '1.5em' }}>
                  <Button secondary onClick={this.openLinkingModal}>Link to PIMS</Button>
                </div>
              )}


              <div style={{ marginTop: '1.5em' }}>
                {!isSuspended ? (
                  <Button danger onClick={this.suspendConfirm}>Suspend Practice</Button>
                ) : (
                  <Button danger onClick={this.reactivateConfirm}>Reactivate Practice</Button>
                )}
              </div>

              <Divider style={{ margin: '20px 0px 20px 0px' }} />

              <Typography variant='h2' style={{ marginTop: '1em', marginBottom: '0.5em' }}>Whitelabel</Typography>

              {this.state.showWhitelabelEdit ? (
                <form>
                  <div style={{ marginBottom: '1.5em', flexDirection: 'column', display: 'column' }}>
                    <input type='file' onChange={this.handleFileChange} />
                    <br />
                    <Typography variant='button' style={{ marginTop: 5 }}>{'PNG/JPG required. Horizontal logos look better. <10 MB preferred.'}</Typography>
                    <br />
                    <Button type='submit' onClick={this.saveWhitelabelLogo} disabled={this.state.file == null} isLoading={this.state.isLoadingWhitelabel} style={{ marginTop: 10 }}>Save Logo</Button>
                  </div>
                </form>
              ) : (
                <>
                  <LineItem
                    description='Whitelabel Logo'
                    value={this.state.practice?.getIsWhitelabeled() ? (
                      <img src={`https://parasight-public-assets.s3.us-east-2.amazonaws.com/practice-logos/${process.env.REACT_APP_NODE_ENV === 'production' ? 'prod' : 'dev'}/${this.state.practice?.id}.png`} alt='Whitelabel Logo' style={{ height: '50px', width: '100px', objectFit: 'contain' }} />
                    ) : 'Not Enabled'}
                  />

                  {this.state.practice?.getIsWhitelabeled() &&
                  <div style={{ marginBottom: '1.5em' }}>
                    <Button danger onClick={() => this.saveWhitelabelLogo(null)}>Disable Whitelabel</Button>
                  </div>}

                  <div style={{ marginBottom: '1.5em' }}>
                    <Button secondary onClick={() => this.setState({ showWhitelabelEdit: true })}>Upload Whitelabel Logo</Button>
                  </div>
                </>
              )}

              <Divider style={{ margin: '20px 0px 20px 0px' }} />

              <div style={{ marginBottom: '1.5em' }}><Button secondary component={Link} to={{ pathname: `/practices/${this.practiceId}/inventory`, state: { backTo } }}>View Practice Inventory</Button></div>

              <div style={{ marginBottom: '1.5em' }}><Button secondary component={Link} to={{ pathname: `/practices/${this.practiceId}/tests`, state: { backTo } }}>View Practice Tests</Button></div>

              <div><Button secondary component={Link} to={{ pathname: `/practices/${this.practiceId}/error-log`, state: { backTo } }}>View Instrument Error Log</Button></div>

              <Divider style={{ margin: '20px 0px 20px 0px' }} />

              <Typography variant='h2' style={{ marginTop: '1em', marginBottom: '0.5em' }}>Users</Typography>

              <UsersTable
                getUsers={listUsers}
                practiceId={this.practiceId}
                headerContent={
                  <Button component={Link} to={{ pathname: `/practices/${this.practiceId}/create-user`, state: { backTo } }}>
                    Create User
                    <AddIcon style={{ marginLeft: '5px' }} />
                  </Button>
                }
                locationState={{ backTo }}
              />

              <Typography variant='h2' style={{ marginTop: '1em', marginBottom: '0.5em' }}>Instruments</Typography>

              <MaterialTable
                title='Instruments'
                data={{
                  mode: 'remote',
                  columns: [
                    {
                      title: 'Serial Number',
                      remoteField: 'serialNumber',
                      value: rowData => rowData.getSerialNumber(),
                      search: 'column'
                    },
                    {
                      title: 'Friendly Name',
                      remoteField: 'friendlyName',
                      value: rowData => rowData.getFriendlyName() || '(None)',
                      search: 'column'
                    },
                    {
                      title: 'View',
                      omitFromExport: true,
                      render: (rowData) => (
                        <Link to={{ pathname: `/instruments/${rowData.getSerialNumber()}`, state: { backTo } }}><VisibilityIcon /></Link>
                      )
                    },
                  ],
                  fetchRemoteData: ({ page, rowsPerPage, where, order = [] }) =>
                    new Promise((resolve, reject) => {
                      let options = {
                        offset: page != null && rowsPerPage != null ? page * rowsPerPage : undefined,
                        limit: rowsPerPage,
                        where,
                        order,
                      };
                      listPracticeInstruments(this.practiceId, { options }).then((res) => {
                        resolve(res);
                      }).catch((error) => {
                        reject(error);
                      });
                    })
                }}
                options={{
                  pageSize: 5,
                  flipHorizontalScroll: true,
                }}
              />

            </>
          )
        )}
      </Container>
    );
  }
}

Practice.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(Practice);
